<template>
  <div class="wrapper">
    <ul>
      <li v-for="(item, index) in KyLinkList" :key="index">
        <a :href="item.link">{{ index + 1 }}. {{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'KYProtocol',
    components: {},
    props: {},
    data() {
      return {
        KyLinkList: [
          {
            name: '《用户服务协议》',
            link: 'https://landing.happyky.cn/user-agreement',
          },
          {
            name: '《儿童个人信息保护政策》',
            link: 'https://landing.happyky.cn/child-protection-policy',
          },
          {
            name: '《个人信息保护政策》',
            link: 'https://landing.happyky.cn/privacy-protocol',
          },
        ],
      };
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style lang="less" scoped>
  ul {
    li {
      margin: 0.3rem;
      a {
        font-size: 0.26rem;
        color: #007aff;
        text-decoration: none;
      }
    }
  }
</style>
